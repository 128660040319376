* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    padding: 0rem;
}

html.index {
    padding: 3rem;
}

:focus {
    outline: none;
}

::-moz-focus-inner {
    border: 0;
}





html,
body {
    font-size: $global-font-size;
    color: $body-font-color;
    font-weight: $font-weight-light;

    h1 {
        font-size: $font-size-extralarge;
        font-weight: $font-weight-light;
        color: $body-font-color;
        line-height: normal;
    }

    h4 {
        font-weight: $font-weight-light;
    }
}










.progressbar {
    display: inline-block;
    width: auto;
    padding: 10rem 5rem;
    text-align: center
}
.circle {
    width: 180px;
    height: 180px;
    margin: 0 auto;
    margin-top: 10px;
    display: inline-block;
    position: relative;
    text-align: center;
}
.circle:after {
    width: 120px;
    height: 120px;
    content: "";
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 30px;
    left: 30px;
}

.circle canvas {
    vertical-align: middle;
    border-radius: 50%;
}
.progressText{
    color:#232227!important;
    font-size: 2rem;
}
.circle div {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -86px;
    width: 100%;
    text-align: center;
    line-height: 40px;
    font-size: 31px;
    
}
.circle strong i {
    font-style: normal;
    font-size: 0.6em;
    font-weight: normal;
}
.circle span {
    display: block;
    color: white;
    margin-top: 12px;
}








.valign {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;

    .valign-inner {
        display: table-cell;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        vertical-align: middle;
    }
}

.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.pace-inactive {
    display: none;
}

.pace .pace-progress {
    background: #29d;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px;
}

#splitscreen {
    width: 100%;
    height: 100%;
}

.section {
    overflow: hidden;
    position: relative;
    float: left;
    width: 100%;
    height: 100%;

    .column {
        letter-spacing: 1px;
        text-align: center;

        strong {
            font-size: 44px;
        }
    }

    .column-left {
        float: left;
        width: 50%;
        background: #ffffff;

        @include breakpoint(small only) {
            width: 100%;
        }

        .noborder {
            border-left: 0px !important;
        }
    }

    .column-right {
        float: right;
        width: 50%;
        background-color: #ffffff;

        &.bgimg1 {
            background-image: url(../img/4.jpg);
            background-size: cover;
            background-position: center;
        }

        @include breakpoint(small only) {
            width: 100%;
        }

        .noborder {
            border-right: 0px !important;
        }
    }

    &:nth-child(2) {
        .column-left {
            &.bgimg1 {
                background-image: url(../img/6.jpg);
                background-size: cover;
                background-position: center;
            }
        }

        .column-right {
            background: #ffffff;
        }
    }

    &:nth-child(3) {
        .column-left {
            background: #ffffff;
        }

        .column-right {
            background: #ffffff;

            &.bgimg1 {
                background-image: url(../img/3.jpg);
                background-size: cover;
                background-position: center;
            }
        }
    }

    &:nth-child(4) {
        .column-left {
            background: #ffffff;

            &.bgimg1 {
                background-image: url(../img/2.jpg);
                background-size: cover;
                background-position: right -3rem;
            }
        }

        .column-right {
            background: #ffffff;
        }
    }

    &:nth-child(5) {
        .column-left {
            background: #ffffff;
        }

        .column-right {
            background: #ffffff;
        }
    }
}

.section.active {
    &:nth-child(2) {
        .column-left {
            &.bgimg1 {
                animation-name: MOVE-BG;
                animation-duration: 0.5s;
                animation-timing-function: ease-out;
                animation-iteration-count: 1;

                @keyframes MOVE-BG {
                    from {
                        transform: translatey(4%);
                        transform: scale(1.1);
                        -webkit-transform: scale(1.1);
                    }

                    to {
                        transform: translatey(0%);
                        transform: scale(1.0);
                        -webkit-transform: scale(1.0);
                    }
                }
            }
        }
    }

    &:nth-child(3) {
        .column-right {
            &.bgimg1 {
                background-position: center;
                animation-name: MOVE-BG;
                animation-duration: 0.5s;
                animation-timing-function: ease-out;
                animation-iteration-count: 1;

                @keyframes MOVE-BG {
                    from {
                        transform: translatey(4%);
                        transform: scale(1.1);
                        -webkit-transform: scale(1.1);
                    }

                    to {
                        transform: translatey(0%);
                        transform: scale(1.0);
                        -webkit-transform: scale(1.0);
                    }
                }
            }
        }
    }

    &:nth-child(4) {
        .column-left {
            &.bgimg1 {
                background-position: right;
                animation-name: MOVE-BG;
                animation-duration: 0.5s;
                animation-timing-function: ease-out;
                animation-iteration-count: 1;

                @keyframes MOVE-BG {
                    from {
                        transform: translatey(4%);
                        transform: scale(1.1);
                        -webkit-transform: scale(1.1);
                    }

                    to {
                        transform: translatey(0%);
                        transform: scale(1.0);
                        -webkit-transform: scale(1.0);
                    }
                }
            }
        }
    }
}

.single-box {
    opacity: 0 !important;

    h4 {
        padding-top: 1rem;
    }
}

.fa,
.fas,
.far {
    color: #29d;
    margin-bottom: 15%;
    opacity: 0.4;
}

.fp-table.active {
    .single-box {
        opacity: 1 !important;
        ;
    }
}

.fp-table.active {
    opacity: 1;
    -webkit-transition: all 0.4s ease-in;
    -moz-transition: all 0.4s ease-in;
    -ms-transition: all 0.4s ease-in;
    -o-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
}

.fp-table {
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}

.card-content {
    padding: 2rem;
}

@media screen and (min-width: 1024px) {
    #splitscreen>.section {
        .column-left {
            transition: all 0.4s ease 0s;
            transform: translateY(100%);
            backface-visibility: hidden;
        }

        .column-right {
            transition: all 0.4s ease 0s;
            transform: translateY(-100%);
            backface-visibility: hidden;
        }

        &.active {
            z-index: 1;

            .column-left,
            .column-right {
                transform: translateY(0);
            }

            ~.section {
                .column-left {
                    transform: translateY(-100%);
                }

                .column-right {
                    transform: translateY(100%);
                }
            }
        }
    }
}

/* prevent fullpage from translating the page */
/* scroll down */
.scrolldown {
    position: absolute;
    bottom: 1.5rem;
    left: -35px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right top 0;
    -ms-transform-origin: right top 0;
    transform-origin: right top 0;
    float: right;
}

.scrolldown i {
    padding-left: 9px;
}

.scrolldown a:hover,
.scrolldown a:focus,
.scrolldown a:active {
    color: #151515 !important;
}

html[data-useragent*='MSIE 10.0'] .scrolldown,
.oldie .scrolldown {
    display: none;
}

.scroll-icon {
    display: inline-block;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $body-font-color !important;
    background: transparent;
    position: relative;
    top: 36px;
    right: 42px;
    -webkit-animation: animate-it 3s ease infinite;
    animation: animate-it 3s ease infinite;
}

/* vertical animation */
@-webkit-keyframes animate-it {

    0%,
    60%,
    80%,
    100% {
        -webkit-transform: translateX(0);
    }

    20% {
        -webkit-transform: translateX(-5px);
    }

    40% {
        -webkit-transform: translateX(20px);
    }
}

@keyframes animate-it {

    0%,
    60%,
    80%,
    100% {
        -webkit-transform: translateX(0);
    }

    20% {
        -webkit-transform: translateX(-5px);
    }

    40% {
        -webkit-transform: translateX(20px);
    }
}

.social-section {
    position: absolute;
    bottom: -0.3rem;
    width: 100%;
    z-index: 2;
    padding-right: 4.7rem;

    ul {
        padding: 0;
        margin-left: 0;

        li {
            display: inline-block;
            margin-right: 1rem;
        }
    }

    i {
        cursor: pointer;
        color: #fff !important;
        -webkit-transition: color .5s ease;
        transition: color .5s ease
    }
}

.social-section-dark i {
    cursor: pointer;
    color: gray !important;
    margin: 0 3px;
    -webkit-transition: color .5s ease;
    transition: color .5s ease
}

.social-section-dark i:hover {
    color: black !important;
}

li.fullSc {
    float: left;
    margin-left: -0.2rem;
}

.insetTitle {
    letter-spacing: -10px;
    text-align: right;
    position: absolute;
    display: table;
    height: 100%;
    left: 0rem;
    bottom: 0;
    display: table;
    vertical-align: bottom;
    padding: 0rem 3rem 6rem 3rem;

    h2 {
        font-size: 8rem;
        color: white;
        display: table-cell;
        vertical-align: bottom;
        font-weight: 100;
        line-height: 6rem;
        text-transform: uppercase;
        opacity: 0;
        -webkit-transition: opacity 2s ease-in;
        -moz-transition: opacity 2s ease-in;
        -ms-transition: opacity 2s ease-in;
        -o-transition: opacity 2s ease-in;
        transition: opacity 2s ease-in;
    }

    &.dx {
        text-align: left;
        left: inherit;
        right: 0rem;
        padding: 0rem 3rem 6rem 3rem;
    }
}

.fp-table.active {
    .insetTitle h2 {
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#fp-nav {
    z-index: 9;
    position: fixed;
    top: -0.4rem;
    left: 0rem;
    padding: 0rem 3rem;
    width: 100%;
    margin: 0 !important;

    ul {
        padding: 0;
        margin: 0;
        width: 100%;

        li {
            list-style: none;
            display: inline-block;
            width: 20%;
            text-transform: uppercase;
        }
    }

    .fp-sr-only {
        font-size: 12px;
        color: silver;
        padding-left: 0rem;
        padding-bottom: 5px;
        transition: width 2s;
        border-left: 0px !important;
        border-top: 0px !important;
        border-right: 0px !important;
        border-radius: 0%;
        height: 1.9rem;
        width: 100%;
        margin-top: 25px;
        margin-left: 0px;
        text-align: left;
        display: block;
        -webkit-transition: color 2s;
        transition: color 2s;
    }

    a.active {
        .fp-sr-only {
            color: #000000;
            background-color: transparent;
            border-bottom: 1px solid silver;
        }
    }
}

/* ---------------------------------------------- /*
 * Mouse animate icon
/* ---------------------------------------------- */
.mouse-icon {
    border: 2px solid gray;
    border-radius: 16px;
    height: 40px;
    width: 24px;
    display: block;
    z-index: 1;
    opacity: 0.7;
    position: absolute;
    bottom: 100px;
    left: 50%;
}

.mouse-icon .wheel {
    -webkit-animation-name: drop;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-play-state: running;
    -webkit-animation-name: drop;
    animation-name: drop;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-play-state: running;
    animation-play-state: running;
}

.mouse-icon .wheel {
    position: relative;
    border-radius: 10px;
    background: #000;
    width: 2px;
    height: 6px;
    top: 4px;
    margin-left: auto;
    margin-right: auto;
}

@-webkit-keyframes drop {
    0% {
        top: 5px;
        opacity: 0;
    }

    30% {
        top: 10px;
        opacity: 1;
    }

    100% {
        top: 25px;
        opacity: 0;
    }
}

@keyframes drop {
    0% {
        top: 5px;
        opacity: 0;
    }

    30% {
        top: 10px;
        opacity: 1;
    }

    100% {
        top: 25px;
        opacity: 0;
    }
}

.split-section-container {
    z-index: 2 !important;
}

.last-section-container {
    z-index: 1;
}

.site-button {
    background-color: #000;
    border: 2px solid #000;
    color: #fff;
    padding: 1rem;
    text-transform: uppercase;
    margin-top: 2rem;
}

#myVideo {
    opacity: 1;
    position: relative;
    z-index: 1;
}

.bottom-content {
    position: absolute;
    z-index: 2;
    top: 0;
    margin: 0px;
    right: 0;
    left: 0;
    background-color: rgba(256, 256, 256, 0.3);
    height: 100%;
    padding-top: 20%;
    color: #000000;
}

.active.fp-completely {
    z-index: 999 !important;
}

.contactme-form {
    padding: 2rem;
}

[type="text"],
[type="password"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="email"],
[type="number"],
[type="search"],
[type="tel"],
[type="time"],
[type="url"],
[type="color"],
textarea {
    box-shadow: none !important;
    text-align: center;
    font-size: 14px;
}

@media screen and (min-width: 1024px) {
    #splitscreen {
        transform: translate3d(0px, 0px, 0px) !important;
    }

    #splitscreen>.section {
        position: absolute;
        top: -3rem;
        left: 0;
    }

    .fp-enabled {
        height: 90%
    }
}

@media screen and (max-width: 1280px) {
    html {
        padding: 1rem !important
    }

    .social-section {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 2;
        padding-right: 0;
        background-color: #fff;
        padding-top: 0.7rem;
        height: 3rem;
    }

    .social-section {
        width: 98.9%;
    }

    #fp-nav {
        padding: 0rem 1rem;
        background-color: rgba(256, 256, 256, 1)
    }

    .card-content {
        padding: 0;
    }

    .card-section {
        padding: 0.5rem;
    }
}

@media screen and (max-width: 1024px) {
    html {
        padding: 0 !important
    }

    /*.single-box.large-4.medium-12.cell.aos-init {
        opacity: 1 !important;
    }
    [data-aos^="fade"][data-aos^="fade"] {
        opacity: 1 !important;
    }*/
    .social-section {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 2;
        padding-right: 0;
        background-color: #fff;
        padding-top: 0.7rem;
        height: 3rem;
    }

    .card-content {
        padding: 1rem;
    }

    .card-section {
        padding: 0rem 2rem;
    }

    h4,
    .h4 {
        line-height: 1.8rem;
        margin-top: 0;
        margin-bottom: 0.5rem;
    }
}

@media screen and (max-width: 741px) {
    p {
        line-height: 1.4;
    }

    h4 {
        font-weight: 100;
        font-size: 1.8rem;
    }

    .hamburger-menu-button {
        width: 40px;
        height: 40px;
        display: block;
        position: fixed;
        z-index: 100;
        background: #fff;
        border: 4px solid #fff;
        box-sizing: content-box;
        text-indent: 100%;
        color: transparent;
        white-space: nowrap;
        cursor: pointer;
        overflow: hidden;
    }

    .hamburger-menu-button-open {
        top: 50%;
        margin-top: -1px;
        left: 50%;
        margin-left: -12px;
    }

    .hamburger-menu-button-open,
    .hamburger-menu-button-open::before,
    .hamburger-menu-button-open::after {
        position: absolute;
        width: 24px;
        height: 2px;
        background: #000;
        border-radius: 4px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    .hamburger-menu-button-open::before,
    .hamburger-menu-button-open::after {
        left: 0;
        content: "";
    }

    .hamburger-menu-button-open::before {
        top: 6px;
    }

    .hamburger-menu-button-open::after {
        bottom: 6px;
    }

    .hamburger-menu-button-close {
        background: transparent;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .hamburger-menu-button-close::before {
        -webkit-transform: translateY(-6px) rotate(45deg);
        transform: translateY(-6px) rotate(45deg);
    }

    .hamburger-menu-button-close::after {
        -webkit-transform: translateY(6px) rotate(-45deg);
        transform: translateY(6px) rotate(-45deg);
    }

    .ham-menu {
        position: fixed;
        top: 48px;
        left: 0;
        margin: auto;
        max-width: none;
        overflow: hidden;
        width: 100%;
    }

    .ham-menu.on {
        z-index: 99;
    }

    .ham-menu ul {
        -webkit-transform: translateY(-110%);
        transform: translateY(-110%);
        background-color: #000000;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        height: 100vh;
        display: table;
        padding-top: 2rem;
        opacity: 0.9;
    }

    .ham-menu.on ul {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    .ham-menu ul {
        font-size: 0;
    }

    .ham-menu ul li {
        display: inline-block;
        width: 100%;
    }

    .ham-menu ul li:first-child .ham-menu ul li a {
        padding-left: 30px;
    }

    .ham-menu ul li a {
        display: block;
        background-color: transparent;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        text-align: center;
        opacity: 0;
    }

    .ham-menu ul li.one1 a {
        -webkit-transition: all 0.1s ease-in;
        transition: all 0.1s linear;
        opacity: 0;
    }

    .ham-menu ul li.one2 a {
        -webkit-transition: all 1s ease-in;
        transition: all 1s ease-in;
        opacity: 0;
    }

    .ham-menu ul li.one3 a {
        -webkit-transition: all 2s ease-in;
        transition: all 2s ease-in;
        opacity: 0;
    }

    .ham-menu ul li.one4 a {
        -webkit-transition: all 3s ease-in;
        transition: all 3s ease-in;
        opacity: 0;
    }

    .ham-menu ul li.one5 a {
        -webkit-transition: all 4s ease-in;
        transition: all 4s ease-in;
        opacity: 0;
    }

    .ham-menu.on ul li a {
        opacity: 1;
    }

    .ham-menu ul li.active a,
    .ham-menu ul li a:hover {
        background-color: #333333;
        color: #fff !important;
        transition: none !important
    }

    .single-box.large-4.medium-12.cell.aos-init.aos-animate {
        margin-bottom: 3rem;
    }

    .social-section {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 2;
        padding-right: 0;
        background-color: #fff;
        padding-top: 0.7rem;
        height: 3rem;
    }

    .sub-title-cont {
        top: 15%;
        position: absolute;
        text-align: center;
        color: #fff;
        width: 95%;

        h2 {
            font-weight: 100;
            font-size: 2rem;
        }
    }

    .section {
        height: auto !important;

        .column-left {
            width: 100% !important;
            position: relative;
        }

        .column-right {
            width: 100% !important;
            position: relative;
        }

        .tap-here {
            position: absolute;
            bottom: 13%;
            margin: auto;
            left: 0;
            right: 0;
            z-index: 9999;

            &.pulsebuttons-container {
                display: flex;

                .pulsebutton {
                    background: #dddddd;
                    border-radius: 50%;
                    box-shadow: 0 0 0 0 rgba(192, 192, 192, 1);
                    margin: 10px;
                    height: 40px;
                    width: 40px;
                    transform: scale(1);
                    animation: pulse-black 2s infinite;
                    margin: auto;
                    opacity: 0.5;
                }

                @keyframes pulse-black {
                    0% {
                        transform: scale(0.95);
                        box-shadow: 0 0 0 0 rgba(192, 192, 192, 0.7);
                    }

                    70% {
                        transform: scale(1);
                        box-shadow: 0 0 0 10px rgba(192, 192, 192, 0);
                    }

                    100% {
                        transform: scale(0.95);
                        box-shadow: 0 0 0 0 rgba(192, 192, 192, 0);
                    }
                }

                .pulsebutton.white {
                    background: white;
                    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
                    animation: pulse-white 2s infinite;
                }

                @keyframes pulse-white {
                    0% {
                        transform: scale(0.95);
                        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
                    }

                    70% {
                        transform: scale(1);
                        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
                    }

                    100% {
                        transform: scale(0.95);
                        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
                    }
                }

                .pulsebutton.red {
                    background: rgba(255, 82, 82, 1);
                    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
                    animation: pulse-red 2s infinite;
                }

                @keyframes pulse-red {
                    0% {
                        transform: scale(0.95);
                        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
                    }

                    70% {
                        transform: scale(1);
                        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
                    }

                    100% {
                        transform: scale(0.95);
                        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
                    }
                }

                .pulsebutton.blue {
                    background: rgba(52, 172, 224, 1);
                    box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
                    animation: pulse-blue 2s infinite;
                }

                @keyframes pulse-blue {
                    0% {
                        transform: scale(0.95);
                        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
                    }

                    70% {
                        transform: scale(1);
                        box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
                    }

                    100% {
                        transform: scale(0.95);
                        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
                    }
                }
            }
        }
    }

    #myVideo {
        margin-top: -116px;
    }

    .bottom-content {
        padding-top: 50%;
        font-weight: 400;
    }

    .card-content {
        padding: 3rem 1rem;
    }

    .card-section {
        padding: 0rem 1rem;
    }
}






.portfolio {
    h1 {
        font-size: 9rem;
        line-height: 7rem;
        padding-left: 2.4rem;
    }

    h2 {
        font-size: 9rem;
        line-height: 7rem;
        padding-left: 2.4rem;
        padding-top: 5%;
        font-weight: 100
    }

    h2.lastmn {
        padding-top: 5%
    }

    .pt-text {
        padding: 2rem 3rem;
        font-size: 3rem;
        line-height: 3rem;
    }

    .step1 {
        .cell.h100 {
            height: 100vh;
        }
    }

    .mySlides {
        display: none
    }

    img {
        vertical-align: middle;
    }

    /* Slideshow container */
    .slideshow-container {
        max-width: 600px;
        position: relative;
        margin: 3rem auto 0 auto;

        background-color: gray;

        &.nl-360 {
            max-width: 360px;
            margin: auto auto;
        }
    }

    /* Next & previous buttons */
    .prev,
    .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        padding: 16px;
        margin-top: -22px;
        color: white;
        font-weight: bold;
        font-size: 18px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
        right: -9rem;
        top: 15rem;
        border-radius: 3px 0 0 3px;

        &.p-bot {
            right: -5rem;

            img {
                height: 50px;
            }
        }
    }

    .prev {
        left: -9rem;
        top: 15rem;
        border-radius: 3px 0 0 3px;

        &.p-bot {
            left: -5rem;

            img {
                height: 50px;
            }
        }
    }

    /* On hover, add a black background color with a little bit see-through */
    .prev:hover,
    .next:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    /* Caption text */
    .text {
        color: #f2f2f2;
        font-size: 15px;
        padding: 8px 12px;
        position: absolute;
        bottom: 8px;
        width: 100%;
        text-align: center;
    }

    /* Number text (1/3 etc) */
    .numbertext {
        color: #f2f2f2;
        font-size: 12px;
        padding: 8px 12px;
        position: absolute;
        top: 0;
    }

    /* The dots/bullets/indicators */
    .dot {
        cursor: pointer;
        height: 15px;
        width: 15px;
        margin: 0 2px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        transition: background-color 0.6s ease;
    }

    .active,
    .dot:hover {
        background-color: #717171;
    }

    /* Fading animation */
    .fade {
        -webkit-animation-name: fade;
        -webkit-animation-duration: 1.5s;
        animation-name: fade;
        animation-duration: 1.5s;
    }

    @-webkit-keyframes fade {
        from {
            opacity: .4
        }

        to {
            opacity: 1
        }
    }

    @keyframes fade {
        from {
            opacity: .4
        }

        to {
            opacity: 1
        }
    }

    .bottom-step {
        background-color: #232327;
        background-image: url(../img/bg4.jpg);
        background-repeat: no-repeat;
        background-position: bottom right;

        .h50 {
            height: 50vh
        }
    }

    .cont-image-bottom {
        position: relative;
        margin-top: 5%;
        text-align: center;

        .img-l5 {
            z-index: 1;
        }

        .img-l6 {
            position: absolute;
            z-index: 2;
            right: 17%;
            margin-top: 10%;
        }
    }

    .dragscroll {
        background-color: #283849;
        overflow: auto;
        width: 600px;
        height: 90vh;
        padding: 0;
        cursor: -webkit-grab;
        cursor: -moz-grab;
        cursor: -o-grab;
        cursor: grab;
        color: #fff;
        overflow: hidden;
    }

    .dragscroll:active {
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
        cursor: -o-grabbing;
        cursor: grabbing;
    }

    .icon {
        display: inline-block;
        width: 200px;
        height: auto;
        vertical-align: top;
        position: absolute;
        margin: auto;
    }

    .icon-wrapper {
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        left: 100px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    //
    // Hand animation
    // --------------------------------------
    .hand-icon {
        position: relative;
    }

    .hand {
        background: #fff;
        width: 5px;
        height: 16px;
        border-radius: 20px;
        position: relative;
        left: -5px;
        margin-bottom: 17px;
        transform: rotate(-20deg);
        animation: handAnim 1.2s infinite;

        // hand
        &:after {
            content: "";
            background: #fff;
            width: 17px;
            height: 18px;
            border-radius: 4px 8px 38px 15px;
            transform: rotate(6deg) skewY(10deg);
            position: absolute;
            top: 13px;
            left: -1px;
        }

        // thumb
        &:before {
            content: "";
            background: #fff;
            width: 6px;
            height: 17px;
            border-radius: 2px 40px 20px 20px;
            position: absolute;
            top: 12px;
            left: -6px;
            transform: rotate(-38deg);
        }

        // circle
        .circle {
            background-color: #fff;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            position: absolute;
            top: -7px;
            left: -7px;
            opacity: .5;
            animation: circleAnim 1.2s infinite 2.1s;
        }
    }

    // Hand animation
    @keyframes handAnim {
        0% {
            top: 20px;
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            top: -20px;
            opacity: 0;
        }
    }

    // Circle animation
    @keyframes circleAnim {
        from {
            transform: scale(0);
        }

        to {
            transform: scale(2);
            opacity: 0;
        }
    }

    //
    // Mouse
    // --------------------------------------
    .mouse-icon {
        position: relative;

        .mouse {
            width: 18px;
            height: 28px;
            background: #fff;
            border-radius: 20px;
            position: relative;
            margin-bottom: 3px;

            &:after {
                content: "";
                background: #D83C40;
                width: 4px;
                height: 6px;
                position: absolute;
                top: 5px;
                left: 50%;
                margin-left: -2px;
                border-radius: 20px;
                animation: scrollAnim 1.1s infinite;
            }
        }

        .arrow {
            width: 6px;
            height: 6px;
            display: block;
            border-bottom: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(45deg);
            position: relative;
            margin: 0 auto;
            animation: arrowAnim 1.2s infinite;

            &.arrow-01 {
                animation-delay: .2s;
            }

            &.arrow-02 {
                animation-delay: .3s;
            }

            &.arrow-03 {
                animation-delay: .4s;
            }
        }
    }

    // Mouse wheel animation
    @keyframes scrollAnim {
        from {
            top: 5px;
        }

        to {
            top: 15px;
            opacity: 0;
        }
    }

    // Arrows animation
    @keyframes arrowAnim {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    //
    // Coffee Cup
    // --------------------------------------
    .coffee-cup {
        background: #fff;
        width: 27px;
        height: 20px;
        border-radius: 0 0 13px 13px;
        position: relative;

        &:after {
            content: "";
            border: 2px solid #fff;
            position: absolute;
            top: 2px;
            right: -8px;
            width: 10px;
            height: 8px;
            border-radius: 0 6px 10px 0;
            transform: rotate(-8deg) skewY(-3deg);
        }

        &:before {
            content: "";
            width: 35px;
            height: 0;
            position: absolute;
            bottom: -6px;
            left: 50%;
            transform: translateX(-49%);
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #fff;
        }

        &-smoke {
            position: absolute;
            top: -14px;
            left: 49%;
            margin-left: 2px;
            animation: smokeAnim 1.8s infinite;
            zoom: 1.5;

            &:after {
                content: "";
                width: 6px;
                height: 7px;
                border-left: 2px solid #fff;
                position: absolute;
                top: -6px;
                transform: translateX(-49%);
                border-radius: 50%;
            }

            &:before {
                content: "";
                width: 6px;
                height: 7px;
                border-right: 2px solid #fff;
                position: absolute;
                left: -4px;
                transform: translateX(-49%);
                border-radius: 50%;
            }
        }

        &-smoke:nth-child(2) {
            left: 25%;
            zoom: 1;
        }

        &-smoke:nth-child(3) {
            left: 75%;
            zoom: 1;
        }
    }

    // Smoke animation
    @keyframes smokeAnim {
        0% {
            top: -2px;
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            top: -20px;
            opacity: 0;
        }
    }

    //
    // Light Bulb
    // --------------------------------------
    .light {
        &-bulb {
            background: #fff;
            width: 28px;
            height: 28px;
            margin-bottom: 6px;
            border-radius: 50%;

            &:before {
                content: '';
                width: 16px;
                height: 0;
                position: absolute;
                top: 20px;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 3px 3px 8px 8px;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 13px solid #fff;
            }

            .thunder {
                position: absolute;
                top: 7px;
                left: 16px;
                transform: rotate(30deg);
                animation: thunderAnim 2s infinite;

                &:before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 2px solid transparent;
                    border-right: 2px solid transparent;
                    border-bottom: 10px solid #D83C40;
                    position: absolute;
                }

                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 2px solid transparent;
                    border-right: 2px solid transparent;
                    border-top: 10px solid #D83C40;
                    position: absolute;
                    top: 9px;
                    left: 2px;
                }
            }
        }

        &-bulb-base {
            background: #fff;
            width: 18px;
            height: 6px;
            position: relative;
            left: 50%;
            border-radius: 2px;
            transform: translateX(-50%);

            &:before {
                content: '';
                background: #fff;
                width: 12px;
                height: 6px;
                border-radius: 3px 3px 23px 23px;
                position: absolute;
                top: 7px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    // Thunder animation
    @keyframes thunderAnim {
        0% {
            opacity: 0;
        }

        3% {
            opacity: 1;
        }

        6% {
            opacity: 0;
        }

        9% {
            opacity: 1;
        }

        20% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        60% {
            opacity: 0;
        }

        80% {
            opacity: 1;
        }
    }

}




/* On smaller screens, decrease text size */
@media only screen and (max-width: 1440px) {

    .portfolio {

        .dragscroll {
            width: auto;
        }


        h1,
        h2 {
            font-size: 7rem;
            line-height: 6rem;
            padding-left: 2.4rem;
        }

        .pt-text {
            font-size: 2rem;
            line-height: 2.3rem;
        }

        .icon {
            top: 3rem;
            left: -6rem;
        }

        .slideshow-container {
            margin: 0rem auto;
        }

        .prev,
        .next {
            background-color: rgba(0, 0, 0, 0.2);

            img {
                width: 25px;
            }


        }

        .prev {
            left: -1rem
        }

        .next {
            right: -1rem
        }

        .next.p-bot {
            right: 0;
            background-color: rgba(0, 0, 0, 0.4);


            img {
                width: 10px;
            }

        }

        .prev.p-bot {
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);


            img {
                width: 10px;
            }
        }
    }


}


@media only screen and (max-width: 1280px) {

    .portfolio {


        padding: 0 !important;

        .slideshow-container {
            border-left: 1px solid #dddddd
        }


        .icon {
            top: 3rem;
            left: 0rem;



            .hand {
                background: gray;


                // hand
                &:after {
                    content: "";
                    background: gray;

                }

                // thumb
                &:before {
                    content: "";
                    background: gray;

                }

                // circle
                .circle {
                    background-color: gray;

                }
            }

        }

    }


}



@media only screen and (max-width: 1024px) {

    .portfolio {



        h1 {
            font-size: 5rem;
            line-height: 5rem;
            padding-left: 1.6rem;
            padding-right: 2rem !important;
            padding-top: 2rem;
        }

        h2 {
            font-size: 2.6rem;
            line-height: 2.5rem;
            padding-left: 1.8rem;
            padding-top: 2rem !important;
            padding-right: 2rem;
        }

        .pt-text {
            padding: 2rem;
            font-size: 1.5rem;
            line-height: 2rem;
        }

    }


    .portfolio .step1 .cell.h100 {
        height: auto;


    }

    .slideshow-container {
        border-left: 0px !important;
    }

    .h50 {
        height: auto !important;
        padding-bottom: 3rem;
    }

}



@media only screen and (max-width: 640px) {

    .hamburger-menu-button{
        right: 0;
        border: 0;
    }

    .ham-menu{
        top: 0!important;
    }

}



@media only screen and (max-width: 480px) {

.portfolio .slideshow-container.nl-360{
    width: 100%!important;
    max-width: none
}

   .dragscroll{
        margin: auto;
        display: none;
        text-align: center;
        width: 100%;

        img{
            max-width: 360px !important;

        }

    }



    .loghi {
        max-width: 200px;
    }


    .bottom-step {
        background-image: none !important
    }

    .img-l5 {
        max-width: 300px;
        opacity: 0.6 !important;
    }

    .img-l6 {
        max-width: 200px;
    }

}




@media only screen and (max-width: 360px) {

    .portfolio .slideshow-container.nl-360{
        width: 100%!important;
        max-width: none
    }
    
       .dragscroll{
            margin: auto;
            display: none;
            text-align: center;
            width: 100%;
    
            img{
                max-width: 320px !important;
    
            }
    
        }
    
    
    
    }