@import 'util/util';
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400,600,700,900);
// custom global variables


// 1. Global
// ---------

$global-font-size: 100%;
$global-width: rem-calc(1200);
$global-lineheight: 1.5;
$foundation-palette: (
  primary: #1779ba,
  secondary: #767676,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
);
$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$site-standard-color: #565656;
$black: #000000;
$white: #fefefe;
$body-background: $white;
$body-font-color: $site-standard-color;
$body-font-family: 'Source Sans Pro', sans-serif;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: pointer;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;

@include add-foundation-colors;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 740px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
$print-breakpoint: large;
$breakpoint-classes: (small medium large);

// 3. typ
// --------------



$font-size-small:0.8rem;
$font-size-large:2rem;
$font-size-extralarge:3.5rem;

$font-weight-light:200;
$font-weight-bold:400;
$font-weight-extrabold:700;


